.footer {
    /* position: fixed; */
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4.25rem;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    /* background: #3E6990; */
    background: #357bb9;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.61);
    z-index: 3;
  }
  
  .footer p {
    margin: 0;
    color: white;
    /* text-transform: uppercase; */
    text-align: right;
    font-style: italic;
  letter-spacing: .1em;
  font-size: 14px;
  font-family: overpass,-apple-system,BlinkMacSystemFont,segoe ui,Roboto,helvetica neue,Arial,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol;
  }
  
  p.contact {
    font-size: 10px;
    font-style: normal;

  }

  .footer .container {
    width: 90%;
    margin: 0 auto;
    padding: 0;
    position: relative;
    align-items: center;
    display: flex;
    justify-content: center;
  }

  @media only screen and (min-width: 600px) {
    .footer p {
      font-size: 18px;
    
    }

    p.contact {
      font-size: 16px;
    }
    }

    .color {
      width: 40px;
      height: 80px;
    }