* {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  font-size: 18px;
   /* overflow-y: scroll; */
  -webkit-text-size-adjust: 18px; 
  -ms-text-size-adjust: 14px; 

}

body {
  margin: 0;
  font-family: overpass,-apple-system,BlinkMacSystemFont,segoe ui,Roboto,helvetica neue,Arial,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol
}