.input {
  margin: 0.5rem 0 0 0;
  width: 100%;
  display: inline-block;
}

@media only screen and (min-width: 600px) {
  .input {
    width: 50%;
    margin: 0.5rem 0;
  }

  /* .input input {
    width: 50%;
  } */
}

.input label {
  font-weight: bold;
  display: block;
  margin-bottom: 0.5rem;
  /* color: #fff; */
  color: #357bb9;
}

.wide > div.input {
  width: 95% !important;
}

.wide > div.input input {
  width: 100%;
}

.input input {
  font: inherit;
  display: block;
  width: 95%;
  background: white;
  padding: 0.15rem 0.25rem;
  color: #357bb9;
  border-radius: 3px;
  -webkit-box-shadow: 0 5px 20px -5px rgba(0,0,0,.5);
 border: 1px solid white;

}

.input select {
  font: inherit;
    display: block;
    width: 95%;
    padding: 0.05rem 0.15rem;
    /* padding: 0.15rem 0.25rem; */
    border: 0;
    border: 1px solid white;
    background: white;
    color: #357bb9;
    font-size: .95rem;
    -webkit-box-shadow: 0 5px 20px -5px rgba(0,0,0,.5);
/* -webkit-appearance: button; */
    
}


.input select:focus {
  outline: none;
}

.input input:focus {
  outline: none;
  /* color: #fff; */
  color: #357bb9;
  /* background: #e7e7e7; */
  /* border-color: black; */
}

.invalid label {
 color: red;
}

.invalid > input {
  border-color: red;
}

.invalid p {
  margin-top: .25rem;
  color: red;
  /* line-height: .25rem; */
  font-size: 12px;
}


form div.noerror {
  font-size: 12px;
  margin-top: .25rem;
  opacity: 0;

}

form div.error {
  font-size: 12px;
  margin-top: .25rem;
  opacity: 1;
}