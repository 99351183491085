.pledge-item {
  /* margin: .5rem; */
  /* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26); */
  /* border-radius: 6px; */
  padding: 1rem;
}

.pledge-item h2 {
  margin: 0.5rem 0 0.5rem 0;
  padding: 0rem 0.5rem;
  font-size: 1.6rem;
  width: 100%;
  word-wrap: break-word;
  color: #343434;
  font-family: dosis,sans-serif;
  text-align: center
}

@media only screen and (min-width: 760px) {
 .pledge-item h2 {
      font-size: 1.75rem;
  }
  }

.pledge-item p {
  margin: 0;
  color: #505050;
  line-height: 2
}
.pledge-line p {
  font-size: .9rem;
}
.pledge-item h2 {
  margin-bottom: .5rem !important;
}

#person-icon {
  font-size: 6rem;
  color: rgb(48, 177, 216)
}

.pledged-amount {
  color: #28a745!important
}

.org-line p {
  font-style: italic;
  line-height: 1.5;
  text-align: center
}