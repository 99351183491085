.button {
  font: inherit;
  padding: 0.5rem 2rem;
  /* background: #3E6990; */
  /* background: white;
  border: 2px solid #fff;
  color: #357bb9; */

  /* color: #3E6990; */
  /* color: #f7ca44; */
  cursor: pointer;
  -webkit-box-shadow: 0 5px 20px -5px rgba(0,0,0,.5);

  background: #357bb9;
  border: 2px solid #357bb9;
  color: #fff
}

.button:focus {
  outline: none;
}

.button:hover,
.button:active {
  background: #f7ca44;
  border: 2px solid;
  color: #fff;
}

.button::-webkit-scrollbar-thumb{
  background: white;
}

#new-pledge .button {
  border-radius: 6px;
}

#new-pledge .button:hover, #new-pledge.button:active {
  background: white;
  border: 2px solid #fff;
  color: #357bb9;
  /* background: #357bb9;
  border: 2px solid;
  color: #fff */
}