.modal-footer .button {
    background: #f7ca44;
      color: #fff;
      border: 2px solid #f7ca44;
      box-shadow: 0 5px 5px -5px rgba(0,0,0,.5);
  }
  
  .modal-footer .button:hover {
    border: 2px solid white;
  }

  .modal-body {
    padding: 2rem 2rem 1rem 2rem;
  }

  .modal-footer {
    padding: .25rem .5rem
  }
