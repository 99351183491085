#introdesc {
    margin: 1.5rem auto 1.5rem auto;
    padding: 0 2rem;
    /* box-shadow: 0 5px 10px -5px rgba(0,0,0,.5); */
    color: #357bb9;
}

@media only screen and (min-width: 600px) {
#introdesc {
    margin: 3rem auto 1rem auto;
    
}
}

#introdesc p {
    margin-top: 1rem;
    font-size: .8rem;
    color: #245682;

}

.imp {
    font-weight: 900;
    font-size: .85rem;
    /* text-shadow: 0px 0px 5px 0px black */
}