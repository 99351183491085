#new-pledge {
  /* width: 90%; */
  /* max-width: 40rem; */
  margin: 1rem auto 1rem auto;
  /* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26); */
  box-shadow: 0 5px 20px -5px rgba(0,0,0,.5);
  /* background: #3E6990; */
  font-size: .85rem;
  background: #f7ca44;
  /* background: #357bb9; */
  border-radius: 6px;
  padding: 1rem;
  color: #fff;
}

@media only screen and (min-width: 993px) {
  #new-pledge {
    margin: 3rem auto 1rem auto !important;
  }
}

@media only screen and (min-width: 600px) and (max-width: 992px)  {
  #new-pledge {
    margin: 1rem auto 2rem auto;
  }
}


.pledgeheader {
  margin: 0;
  margin-bottom: .25rem;
}

#new-pledge h2{
  /* margin-left: 1rem; */
  font-weight: 300;
  font-family: dosis,sans-serif;
  color: #357bb9;
  
}

.submitted {
  padding: 1.25rem 2rem;
}

.submitted .row {
  margin-bottom: 1rem;
}

.submitted button {
  margin: 0 .25rem;
  /* position: absolute;
  top: 2px;
  left: 2px; */
}

svg {
  width: 2rem;
  height: 2rem;
}

/* .iconholder {
  position: relative;
}
.iconborder {
    left: 0;
    position: absolute;
    top: 0;
    width: 36px;
    height: 36px;
    border-radius: 100%;
    margin: 0 .25rem;
    background: rgba(255, 255, 255, .5)
} */

#new-pledge h3{
  margin: 0 auto;
  font-size: 1.5rem;
  font-weight: 300;
  font-family: dosis,sans-serif;
  vertical-align: center;
}

