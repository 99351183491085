.totalpledgedblock {
    font-size: 1.5rem;
    margin-top: .5rem;
  }

  .totalpledgedblock p {
    line-height: 1em;
    text-align: center;

  }
  
  .totalpledged {
    font-size: 4rem;
    line-height: 1;
    color: #3392e6;
  }
  
  .pledges {
    /* color: #28a745; */
    /* padding: 0 .25rem */
  }

  #summary{
    /* margin: 1rem auto 1rem auto; */
    padding: 1rem;
  }

