#pledges {
  width: 90%;
  /* max-width: 40rem; */
  margin: 2rem auto;
}

@media only screen and (min-width: 800px) {
  #pledges {
    width: 90%
  }
}
#pledges > p {
  text-align: center;
}

.pledge-list {
  width: 100%;
  margin: 1.5rem auto 0;
  /* margin-top: 1.5rem; */
  padding: 0;
}

@media only screen and (min-width: 850px) {
  .pledge-list {
    width: 90%;
  }
}

.pledge-list h2 {
  margin-bottom: 1rem;
}

.seemore {
  margin: 1rem 0;
}

.seemore button {
  background: none;
  color: #3392e6;
  -webkit-box-shadow: 0 0px 0px 0px rgba(0,0,0,.5);
  text-transform: uppercase;
  text-decoration: underline;
  font: inherit;
  padding: 0.5rem 2rem;
  border: 2px solid #fff;
  cursor: pointer;
}

.seemore button:hover,
.seemore button:active {
  border: 2px solid white;
  text-decoration: none;
}

.seemore button:focus {
  outline: none
}