main {
  /* margin-top: 5rem; */

}

.loader {
  text-align: center;
  font-size: 1.5rem;
}

.firstrow {
  align-items: center;
}

h2.loader {
font-weight: 300;
font-family: dosis,sans-serif;
}

