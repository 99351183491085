.header {
  /* position: fixed; */
  top: 0;
  left: 0;
  width: 100%;
  height: 4.25rem;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  /* background: #3E6990; */
  background: #357bb9;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.61);
  z-index: 3;
}

.header h1 {
  margin: 0;
  color: white;
  text-transform: uppercase;
letter-spacing: .1em;
font-size: 22px;
font-family: overpass,-apple-system,BlinkMacSystemFont,segoe ui,Roboto,helvetica neue,Arial,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol;
}

.headerholder {
  width: 90%;
  margin: 0 auto;
}

@media only screen and (min-width: 600px) {
.header h1 {
  font-size: 30px;

}
}